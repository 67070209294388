<template>
  <div>
    <v-container fluid class="px-6 py-6">
      <v-row class="mt-8">
        <v-col cols="12">
          <div>
            <v-card class="card-shadow border-radius-xl">
              <div class="card-header-padding">
                <div class="d-flex align-center">
                  <div>
                    <h5 class="font-weight-bold text-h5 text-typo mb-0">
                      Application module
                    </h5>
                    <!-- <p class="text-sm text-body mb-0">
                      Possible states of a document
                    </p> -->
                  </div>

                  <v-dialog v-model="dialog" max-width="500px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        elevation="0"
                        dark
                        :ripple="false"
                        height="43"
                        class="
                          text-capitalize
                          ms-auto
                          btn-primary
                          bg-success
                          py-3
                          px-6
                          ms-3
                        "
                        >New App
                      </v-btn>
                    </template>
                    <v-card class="card-shadow border-radius-xl">
                      <div class="card-header-padding card-border-bottom">
                        <span class="font-weight-bold text-h5 text-typo mb-0">{{
                          formTitle
                        }}</span>
                      </div>
                      <v-card-text class="card-padding">
                        <v-container class="px-0">
                          <v-row>
                            <v-col cols="12">
                              <label
                                class="
                                  text-md text-typo
                                  font-weight-bolder
                                  ms-1
                                "
                                >App module Name</label
                              >
                              <v-form ref="frm">
                                <v-text-field
                                  v-model="editedItem.app_module_name"
                                  hide-details="auto"
                                  class="
                                    input-style
                                    font-size-input
                                    text-light-input
                                    placeholder-light
                                    input-icon
                                    mt-2
                                  "
                                  dense
                                  flat
                                  filled
                                  solo
                                  height="43"
                                  placeholder="Enter App Module Name"
                                  :rules="[
                                    (v) => !!v || 'App Module is required',
                                  ]"
                                  persistent-hint
                                ></v-text-field>
                              </v-form>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col>
                              <label
                                class="
                                  text-md text-typo
                                  font-weight-bolder
                                  ms-1
                                "
                                >Document
                              </label>
                              <v-select
                                v-model="editedItem.documents"
                                :items="documents"
                                return-object
                                item-text="document_name"
                                item-value="id"
                                class="
                                  input-style
                                  font-size-input
                                  text-light-input
                                  placeholder-light
                                  select-style
                                  mt-3
                                "
                                outlined
                                chips
                                multiple
                                height="46"
                              >
                                <template v-slot:selection="{ item, index }">
                                  <v-chip
                                    v-if="index === 0"
                                    label
                                    color="bg-default"
                                    class="py-1 px-2 my-0"
                                  >
                                    <span
                                      class="text-white text-caption ls-0"
                                      >{{ item.document_name }}</span
                                    >
                                  </v-chip>
                                  <span
                                    v-if="index === 1"
                                    class="grey--text text-caption"
                                  >
                                    (+{{ editedItem.documents.length - 1 }}
                                    others)
                                  </span>
                                </template>

                                <template v-slot:prepend-item>
                                  <v-list-item
                                    ripple
                                    @mousedown.prevent
                                    @click="toggle"
                                  >
                                    <v-list-item-action>
                                      <v-icon
                                        :color="
                                          editedItem.documents.length > 0
                                            ? 'indigo darken-4'
                                            : ''
                                        "
                                      >
                                        {{ icon }}
                                      </v-icon>
                                    </v-list-item-action>
                                    <v-list-item-content>
                                      <v-list-item-title>
                                        Select All
                                      </v-list-item-title>
                                    </v-list-item-content>
                                  </v-list-item>
                                  <v-divider class="mt-2"></v-divider>
                                </template>
                              </v-select>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card-text>

                      <v-card-actions class="card-padding d-flex justify-end">
                        <v-btn
                          @click="close"
                          elevation="0"
                          :ripple="false"
                          height="43"
                          class="
                            font-weight-bold
                            text-capitalize
                            btn-ls btn-secondary
                            bg-light
                            py-3
                            px-6
                          "
                          >Cancel</v-btn
                        >

                        <v-btn
                          @click="save"
                          elevation="0"
                          :ripple="false"
                          height="43"
                          dark
                          class="
                            text-capitalize
                            btn-ls btn-primary
                            bg-success
                            py-3
                            px-6
                          "
                          >Save</v-btn
                        >
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </div>
              </div>

              <v-card-text class="px-0 py-0">
                <v-data-table
                  :headers="headers"
                  :items="items"
                  :search="search"
                  class="table"
                  :page.sync="page"
                  hide-default-footer
                  @page-count="pageCount = $event"
                  :items-per-page="itemsPerPage"
                  mobile-breakpoint="0"
                >
                  <template v-slot:top>
                    <v-toolbar flat height="80">
                      <v-row>
                        <v-col cols="12" md="5">
                          <v-text-field
                            hide-details
                            class="
                              input-style
                              font-size-input
                              text-light-input
                              placeholder-light
                              input-icon
                            "
                            dense
                            flat
                            filled
                            solo
                            height="43"
                            v-model="search"
                            placeholder="Search"
                          >
                            <template slot="prepend-inner">
                              <v-icon color="#adb5bd" size=".875rem"
                                >fas fa-search</v-icon
                              >
                            </template>
                          </v-text-field>
                        </v-col>
                      </v-row>

                      <v-spacer></v-spacer>

                      <v-dialog v-model="dialogDelete" max-width="500px">
                        <v-card
                          class="card-shadow card-padding border-radius-xl"
                        >
                          <v-card-title
                            class="pt-0 text-h5 text-typo justify-center"
                            >Are you sure you want to delete this
                            item?</v-card-title
                          >
                          <v-card-actions class="pb-0">
                            <v-spacer></v-spacer>
                            <v-btn
                              @click="closeDelete"
                              elevation="0"
                              :ripple="false"
                              height="43"
                              class="
                                font-weight-bold
                                text-capitalize
                                btn-ls
                                bg-light
                                py-3
                                px-6
                              "
                              >Cancel</v-btn
                            >

                            <v-btn
                              @click="deleteItemConfirm"
                              elevation="0"
                              :ripple="false"
                              height="43"
                              class="
                                font-weight-bold
                                text-capitalize
                                btn-ls btn-primary
                                bg-success
                                py-3
                                px-6
                              "
                              >Ok</v-btn
                            >
                            <v-spacer></v-spacer>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                    </v-toolbar>
                  </template>
                  <!-- <template v-slot:expanded-item="{ headers, item }">
                    <td :colspan="headers.length" class="my-2">
                      Document
                      <br />
                      <v-chip
                        class="ma-2"
                        v-for="doc in item.documents"
                        :key="doc.id"
                      >
                        <v-col cols="1">
                          {{ doc.document_name }}
                        </v-col>
                      </v-chip>
                    </td>
                    <br />
                  </template> -->
                  <template v-slot:[`item.doc`]="{ item }">
                    <v-chip
                      class="ma-2"
                      v-for="doc in item.documents"
                      :key="doc.id"
                    >
                      {{ doc.document_name }}
                    </v-chip>
                  </template>

                  <!-- [`item.actions`]="{ item }" -->
                  <template v-slot:[`item.actions`]="{ item }">
                    <!-- <span> Example test {{ item.district }}</span> -->
                    <v-btn
                      @click="editItem(item)"
                      icon
                      elevation="0"
                      :ripple="false"
                      height="28"
                      min-width="36"
                      width="36"
                      class=""
                      color="green"
                      >Edit
                    </v-btn>

                    <v-btn
                      @click="deleteItem(item)"
                      icon
                      elevation="0"
                      :ripple="false"
                      height="28"
                      min-width="36"
                      width="36"
                      class="ml-5"
                      color="#FF0000"
                    >
                      delete
                    </v-btn>
                  </template>
                </v-data-table>
              </v-card-text>
              <v-card-actions class="card-padding">
                <v-row>
                  <v-col cols="6" lg="4" class="d-flex align-center">
                    <span class="text-body me-3 text-sm">Items per page:</span>
                    <v-select
                      hide-details
                      :items="paginationLimits"
                      item-text="label"
                      item-value="id"
                      outlined
                      background-color="rgba(255,255,255,.9)"
                      color="rgba(0,0,0,.6)"
                      light
                      v-model="itemsPerPage"
                      placeholder="Items per page"
                      class="
                        font-size-input
                        placeholder-lighter
                        text-color-light
                        input-alternative input-focused-alternative input-icon
                      "
                    >
                    </v-select>
                  </v-col>
                  <v-col cols="6" class="ml-auto d-flex justify-end">
                    <v-pagination
                      prev-icon="fa fa-angle-left"
                      next-icon="fa fa-angle-right"
                      class="pagination"
                      color="#38bd34"
                      v-model="page"
                      :length="pageCount"
                      circle
                    ></v-pagination>
                  </v-col>
                </v-row>
              </v-card-actions>
            </v-card>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
// import axios from "axios";
import { paginationLimits } from "../../../../global-data/paginationLimits";

import api from "../api";

export default {
  name: "paginated-tables",

  data() {
    return {
      page: 1,
      pageCount: 0,
      paginationLimits: paginationLimits,
      itemsPerPage: 10,

      dialog: false,
      dialogDelete: false,
      items: [],
      documents: [],
      search: "",
      editedIndex: -1,
      editedItem: {
        app_module_name: "",
        documents: [],

        id: 0,
      },
      defaultItem: {
        app_module_name: "",
        documents: [],

        id: 0,
      },
      headers: [
        {
          text: "App Module",
          align: "start",
          sortable: true,
          value: "app_module_name",
        },
        {
          text: "Documents",
          align: "start",
          sortable: true,
          value: "doc",
        },

        { text: "Actions", value: "actions", sortable: false },
      ],
    };
  },

  created() {
    this.initialize();
  },
  methods: {
    validate() {
      return this.$refs.frm.validate();
    },
    showSuccessAlert(message) {
      this.$swal({
        title: "Success",
        text: message,
        type: "success",
        timer: 3000,
        icon: "success",
        showConfirmButton: false,
      });
    },

    showErrorAlert(message) {
      this.$swal({
        title: "Error",
        text: message,
        type: "error",

        icon: "error",
        showConfirmButton: true,
      });
    },

    async initialize() {
      this.items = await api.get();
      this.documents = await api.getDocuments();
    },

    editItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      console.log(this.editedItem);
      this.dialog = true;
    },

    async deleteItem(item) {
      console.log(item);
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    async deleteItemConfirm() {
      this.closeDelete();
      try {
        await api.delete(this.editedItem.id);
        this.items.splice(this.editedIndex, 1);

        this.showSuccessAlert(`App module Deleted.`);
      } catch (error) {
        this.showErrorAlert(error.message);
      }
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    async save() {
      if (this.validate()) {
        try {
          let type = "Saved";
          if (this.editedIndex > -1) {
            type = "Updated";
            await api.edit(this.editedItem.id, this.editedItem);
            Object.assign(this.items[this.editedIndex], this.editedItem);
          } else {
            let item = await api.create(this.editedItem);
            this.items.push(item);
          }
          this.showSuccessAlert(`App module ${type}.`);
          this.close();
        } catch (err) {
          this.showErrorAlert(err.message);
        }
      }
    },
    toggle() {
      this.$nextTick(() => {
        if (this.selectAll) {
          this.editedItem.documents = [];
        } else {
          this.editedItem.documents = this.documents.slice();
        }
      });
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New App module  " : "Edit App module";
    },

    pages() {
      return this.pagination.rowsPerPage
        ? Math.ceil(this.items.length / this.pagination.rowsPerPage)
        : 1;
    },
    selectAll() {
      return this.editedItem.documents.length === this.documents.length;
    },
    selectSome() {
      return this.editedItem.documents.length > 0 && !this.selectAll;
    },
    icon() {
      if (this.selectAll) return "ni-fat-remove text-lg";
      if (this.selectSome) return "ni-fat-delete";
      return "ni-checkbox-blank-outline";
    },
  },
};
</script>
